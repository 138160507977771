<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems"/>
    <hr/>

    <c-flex mt="30px">
      <c-text
          flex="1"
          font-family="Montserrat"
          font-size="24px"
          font-weight="700"
          line-height="36px"
      >
        Detail Notifikasi
      </c-text>
      <c-stack v-if="isEditable" is-inline spacing="10px">
        <c-button
            variant-color="brand"
            rounded="full"
            as="router-link"
            :to="{ name: 'superadmin.notifikasi-edit', params: $route.params }"
        >
          <c-image
              :src="require('@/assets/icon-pencil.svg')"
              alt="edit icon"
              size="24px"
          />
          <c-text ml="5px">Edit</c-text>
        </c-button>
        <c-button
            type="button"
            rounded="full"
            variant-color="red"
            @click="cancelNotifikasi()"
        >
          <c-image :src="require('@/assets/icon-trash.svg')" alt="icon trash"/>
          <c-text ml="5px">Cancel</c-text>
        </c-button>
      </c-stack>
    </c-flex>
    <c-box mt="20px">
      <c-box
          v-for="(item, index) in items"
          :key="index"
          :mt="index === 0 ? '0' : '10px'"
      >
        <c-text
            color="brand.900"
            font-size="16px"
            font-weight="500"
            line-height="24px"
        >
          {{ item.label }}
        </c-text>
        <c-text font-size="18px" font-weight="400" line-height="27px">
          {{ item.value }}
        </c-text>
      </c-box>
      <c-box mt="10px">
        <c-text
            color="brand.900"
            font-size="16px"
            font-weight="500"
            line-height="24px"
        >
          Target
        </c-text>
        <c-checkbox-group v-if="value" is-disabled v-model="value.targetRole">
          <c-checkbox value="client" v-chakra="{ pointerEvents: 'none' }">
            Client
          </c-checkbox>
          <c-checkbox value="nutritionist" v-chakra="{ pointerEvents: 'none' }">
            Ahli Gizi
          </c-checkbox>
        </c-checkbox-group>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumb.vue";
import {formatDate} from "@/utils/format-date";
import {capitalize} from "lodash/string";
import dayjs from "dayjs";
import "dayjs/plugin/duration";

export default {
  name: "SUNotifikasiDetail",
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    notifikasiId() {
      return this.$route.params.notifikasiId;
    },
    breadcrumbItems() {
      return [
        {
          label: "Manajemen Notifikasi",
          href: this.$router.resolve({name: "superadmin.notifikasi"}).href,
        },
        {label: "Detail Notifikasi", href: null, isCurrent: true},
      ];
    },
    items() {
      return [
        {
          label: "Tanggal",
          value: formatDate(this.value?.createdAt, "DD MMMM YYYY") ?? "-",
        },
        {label: "Dibuat Oleh", value: this.value?.createdBy?.fullName ?? "-"},
        {label: "Nama Notifikasi", value: this.value?.name ?? "-"},
        {label: "Tipe", value: capitalize(this.value?.type)},
        {
          label: "Tanggal & Waktu",
          value:
              formatDate(this.value?.sendAt, "DD MMMM YYYY - HH:mm:ss") ?? "-",
        },
        {label: "Isi Notifikasi", value: this.value?.content ?? "-"},
      ];
    },
    isEditable() {
      if (this.value == null) return false;

      let today = dayjs();
      let startTime = dayjs(this.value?.sendAt);
      let diff = startTime.diff(today);

      return this.value?.type === "scheduler" && diff > 0;
    },
  },
  watch: {
    notifikasiId: {
      immediate: true,
      async handler(notifikasiId) {
        this.value = await this.$store.dispatch(
            "suManagementNotifikasi/getNotifikasiById",
            notifikasiId
        );
      },
    },
  },
  methods: {
    async cancelNotifikasi() {
      await this.$store.dispatch("suManagementNotifikasi/cancel", this.value.id)
          .then(() => {
            this.$successToast({
              message: "Notifikasi berhasil dibatalkan",
            });
          }).catch((e) => {
            this.$errorToast({
              message: e.response?.data?.message ?? e.toString(),
            })
          })
    },
  },
};
</script>

<style scoped></style>
